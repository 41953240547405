@import "../../styles/responsiveness.scss";
@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.mobile-header{
  display:flex;
  flex-direction: row;
  height: 5.5%;
  justify-content: space-between;

  @include mq-desktop {
    display: none;
  }
  @include mq($screen-sm-max, 'landscape') {
    height: 50px;
  }
}
.burger-container {
  height: 100%;
  margin-left: 10px;
  margin-top: 7px;
  width: 35%;
}

.burger-icon-link {
  display: inline-block;
  line-height: 8.5px;
  text-decoration: none;
  width: 33px;
}

.white-burger-icon-color {
  background-color: black !important;
}

.active-slide-panel {
  margin-left: 20px;
  margin-top: 19px;
}

.burger-span-element {
  display: inline-block;
  height: 3px;
  width: 33px;
}

.view-button.ui.secondary.menu {
  width: 35%;
  margin: 0;
  justify-content: flex-end;
}

.logo-mobile {
  width: 30%;
  margin: 0;
  justify-content: center;
  text-align: center;
}