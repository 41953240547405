@import "../../styles/responsiveness.scss";
@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";



.details {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

.table-details {
  background-color: rgb(255, 255, 255) !important;
  width: 90% !important;
  margin: 0% !important;
}


