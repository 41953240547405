#mapDesk {
  width: 100%; 
  height: 95%;
}

#mapMobile {
  width: 100%; 
  height: 100%;
}

.leaflet-top,.leaflet-bottom {
  z-index: 900 !important;
}

