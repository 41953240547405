@import "../../styles/responsiveness.scss";
@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.sidebar {
  list-style: none;
  padding: 0;
  border: none;
  margin: 0;
  clear: both;

  @include mq($screen-md-lg-max) {
    padding-left: 18px;
  }

  button {
    width: 25%;
  }

  div {
    padding: 20px 0 20px 0;
    text-align: center;
    margin: 0 auto;
    color: #ffffff;
    font-size: 10px;

    @include mq($screen-md-lg-max) {
      font-size: 16px;
      padding-left: 12px;
      padding: 0px;
    }

    &.disabled {
      color: #1f1f1f;
    }

    &.active {
      a {
        color: #ffa800;
        text-decoration: none;
      }
    }
    &.disabled {
      a {
        color: black;
        cursor: default;
        &:hover {
          color: black;
        }
      }
    }

    a {
      margin: 0px;
      padding: 0px;
      text-decoration: none;
      color: #ffffff;
      &:hover {
        color: #ffa800;
        text-decoration: none;
      }
    }
  }
}

.sidebar-container {
  //background: #555555;
  background: #ffffff;
  z-index: 99;

  .sidebar {
    margin: 0;
    list-style: none;
    padding: 0;

    div {
      padding: 20px 0 20px 0;
      text-align: center;
      margin: 0 auto;
      color: #555555;
      font-size: 15px;
    }

    div a {
      color: black;
    }

    div.disabled {
      color: gray;
    }

    div a:hover {
      color: blue;
      text-decoration: none;
    }
  }
}

.hamburger-container {
  display: none;
  padding: 0;
}

@include mq-desktop {
  .bar-wrapper {
    height: 11%;

    .sidebar-container {
      height: 100%;

      .rowNav {
        height: 100%;
        .sidebar {
          padding: 0 10px;
          display: flex;
          flex-direction: row;
          height: 100%;
          align-items: center;

          div {
            color: #555555;
            display: inline-block;
            font-size: 15px;
            margin-right: 22px;
          }

          .logoNav {
            flex: 1 1 100%;
            text-align: left;
          }

          .btn-group {
            justify-content: center;
            white-space: nowrap;
          }
          .reserved-area {
            justify-content: flex-end;
            align-content: center;
            white-space: nowrap;
          }
        }
      }
    }
  }
}

@include mq($screen-md-lg-max) {
  .hamburger-container {
    display: block;
  }

  .overlay-container {
    @include transition(opacity 0.3s);
    @include Opacity(0);
    background: rgba(0, 0, 0, 0.4);
    bottom: 0;
    font-family: Arial, Helvetica, sans-serif;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 999;

    &.hide-menu {
      @include translate3d(100%, 0, 0);
      @include transition(opacity 0.3s, transform 0s 0.3s);
    }

    &.show-menu {
      @include Opacity(1);
    }
  }

  .logo-container {
    margin: 0 auto;
    width: 100%;
  }

  .sidebar-container {
    @include transition(all 0.3s cubic-bezier(0, 0.52, 0, 1));
    height: 100vh;
    left: 0;
    overflow: scroll;
    position: fixed;
    top: 0;
    width: 250px;
    z-index: 1000;

    &.hide-menu {
      @include translate3d(-300px, 0, 0);
    }

    &.show-menu {
      @include translate3d(0px, 0, 0);
      overflow: hidden;
    }

    .imageLogo{
      margin-left: auto;
      margin-right: auto;
    }
  }
}
