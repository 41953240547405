@import "../../styles/responsiveness.scss";
@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";


#chart1 {
  width: 100%;
  height: 94%;
}

@include mq($screen-md-lg-max) {
  .tooltip {
    position: fixed;
    bottom: 35vh;
    right: 25vw;
    // z-index: 50;
    border: 2px solid rgb(0, 108, 169);
    border-radius: 5px;
    background-color: #797272;
    padding: 5px;
    font-size: 9pt;
  }
}
@include mq-desktop {
  .tooltip {
    position: relative;
    // z-index: 50;
    border: 2px solid rgb(0, 108, 169);
    border-radius: 5px;
    background-color: #797272;
    padding: 5px;
    font-size: 9pt;
  }

}

