@import "../../styles/responsiveness.scss";
@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";


.main-wrapper {
  height: 100%;
}
.first-row-desktop{
  width: 100%;
  height: 60%;
  padding-bottom: 1%;
}
.first-row-mobile{
  width: 100%;
  height: 60%;
  padding-bottom: 2%;
  padding-top: 1%;
}
.second-row{
  padding-bottom: 0.5%;
  display: flex;
  width: 100%;
  height: 40%;
}
.table-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}


@include mq-desktop {
  .first-row-mobile{
    display:none
  }
  .details-container {
    width: 20%;
    height: 100%;
  }

  .table-container {
    height: 92%;
  }

  .chart-container {
    width: 80%;
    height: 100%;
  }
}

@include mq($screen-md-lg-max) {
  .first-row-desktop{
    display:none
  }
  .details-container {
    display: none;
  }

  .chart-container {
    width: 100%;
    height: 100%;
  }

  .table-container {
    height: 100%;
  }

  // .Tab {
  //   height:100% !important;
  // }

  // .ui.attached.tabular.menu {
  //   height:10%;
  // }
  // .tabPane {
  //   height:90%;
  // }
}






