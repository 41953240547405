@import "./styles/responsiveness.scss";
@import "./styles/variables.scss";
@import "./styles/mixins.scss";

.default-layout {
  width: 100vw;
  height: 100vh;
  // min-height: -webkit-fill-available;
  // height: -webkit-fill-available;
  //height: calc(100vh - 56px);
  // @supports (-webkit-appearance: none) {
  //   .os-android & {
  //     height: calc(100vh - 56px);
  //   }
  // }
}

body {
  padding: 0;
  margin: 0;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

header, footer {
  background: white;
  color: gray;
}


.layout-footer {
  padding: 10px;
  background: gray;
  color: white;
}

@include mq-desktop {
  .main-container{
    padding-top: 1%;
    height: 89%;
  }
}

@include mq($screen-md-lg-max) {
  .main-container{
    height: 94.5%;
  }
}

.main-container {
  padding: 0px 10px 0px 10px;
  @include mq($screen-sm-max, 'landscape') {
    height: 720px;
  }
}

